const mainElement = document.querySelector(".main");
const allTextNodes = Array.from(document.querySelectorAll(".text"));

const handleClickInMain = (ev) => {
  if (ev.target.classList.contains("transformationTrigger")) {
    const textBlock = ev.target.parentNode.querySelector(".text");
    const linkBlock = ev.target.parentNode.querySelector(".link");
    if (textBlock.classList.contains("open")) {
      textBlock.classList.remove("open");
      linkBlock.textContent = `${linkBlock.dataset.text}`;
    } else {
      textBlock.classList.add("open");
      linkBlock.textContent = "\u2190 back";
    }
  }
};

mainElement.addEventListener("click", handleClickInMain);
